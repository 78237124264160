import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { POOL_STAGE_DATA } from 'store/mockData';
import { changePhase } from 'store/secretaryEventConfig/secretaryEventConfig';
import translationPageManager from 'utils/translationPageManager';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import SecretaryPoolsStageComponent from './components/SecretaryPoolsStageComponent';
import PlayoffBracketComponent from 'components/PlayoffBracketComponents/PlayoffBracketComponent';
import SecretarySwissSystemStageComponent from './components/SecretarySwissSystemStageComponent';
const SecretaryStagePage = () => {
    const { pathname } = useLocation();
    const { eventAddress, tournamentAddress } = useSecretaryPathParserHook();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { checkTranslationPageAreExisting } = translationPageManager();
    const currentStageIndex = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex);
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[currentStageIndex]);
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    useEffect(() => {
        if (!stage)
            navigate('/secretary/event/' + eventAddress);
    }, [stage]);
    useEffect(() => {
        dispatch(changePhase({
            phaseTranslation: 'stagesPhase',
            currentSecretaryPath: pathname
        }));
        checkTranslationPageAreExisting();
    }, []);
    const stageType = {
        'pools': _jsx(SecretaryPoolsStageComponent, { tournament: tournament, stage: stage && stage.type === 'pools' ? stage : POOL_STAGE_DATA }),
        'playoff': stage && stage.type === 'playoff' && stage.isCorrectPlayoff
            ? _jsx("div", Object.assign({ style: styles.playoffContainer }, { children: _jsx(PlayoffBracketComponent, { playoffRounds: stage.playoffRounds, isSecretaryPage: true }) }))
            : null,
        'swiss system': _jsx(SecretarySwissSystemStageComponent, {})
    };
    return (_jsx(Container, Object.assign({ sx: { bgcolor: 'primary.main' }, style: styles.container, maxWidth: false }, { children: stage
            ? stageType[stage.type]
            : null })));
};
export default SecretaryStagePage;
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        overflowX: 'scroll',
        backgroundColor: '#000'
    },
    playoffContainer: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        width: '100%'
    },
};
