import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import ModeToggle from './event/components/ModeToggle';
const MainPage = () => {
    const navigationType = useSelector((state) => state.navigationBarConfigReducer.navigationType);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const clearCachesCallback = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name).then();
            });
        });
        localStorage.removeItem('persist:root');
        window.location.reload();
    };
    return (_jsxs(Container, Object.assign({ sx: { bgcolor: 'secondary.main' }, style: styles.container, maxWidth: false }, { children: [_jsx(ModeToggle, {}), navigationType === 'demoNavigation'
                ? _jsxs("div", Object.assign({ style: styles.variableDemoContentContainer }, { children: [_jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.configRowContainer), { margin: '1rem 0 3rem 0' }) }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: styles.text }, { children: "\u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0441\u043E\u0437\u0434\u0430\u0442\u044C \u043F\u0440\u043E\u0431\u043D\u044B\u0439 \u0442\u0443\u0440\u043D\u0438\u0440:" })), _jsx(Button, Object.assign({ style: styles.button, onClick: () => navigation('/event/add') }, { children: _jsx(Typography, { children: "\u041D\u043E\u0432\u044B\u0439 \u0442\u0443\u0440\u043D\u0438\u0440" }) })), _jsx("div", { style: { flex: 1 } }), _jsx(Button, Object.assign({ style: styles.button, onClick: () => clearCachesCallback() }, { children: _jsx(Typography, { children: "\u041E\u0447\u0438\u0441\u0442\u0438\u0442\u044C \u043A\u044D\u0448\u0438" }) }))] })), _jsxs("div", Object.assign({ style: styles.configRowContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: styles.text }, { children: "\u0418\u043B\u0438 \u043F\u0440\u043E\u0441\u0442\u043E \u0432\u043E\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u0442\u0430\u0431\u043B\u043E \u0431\u0435\u0437 \u043B\u0438\u0448\u043D\u0438\u0445 \u043D\u0430\u0441\u0442\u0440\u043E\u0435\u043A:" })), _jsx(Button, Object.assign({ style: styles.button, onClick: () => navigation('/standalone/controlpanel') }, { children: _jsx(Typography, { children: "\u0422\u0430\u0431\u043B\u043E" }) }))] }))] }))
                : _jsxs("div", Object.assign({ style: styles.variableFullContentContainer }, { children: [_jsx("div", { style: { flex: 21 } }), _jsx(Typography, Object.assign({ variant: 'h2' }, { children: "\u041F\u043E\u043B\u043D\u0430\u044F \u0432\u0435\u0440\u0441\u0438\u044F \u0432 \u0440\u0430\u0437\u0440\u0430\u0431\u043E\u0442\u043A\u0435. \u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u043E\u043F\u0440\u043E\u0431\u043E\u0432\u0430\u0442\u044C \u043F\u0440\u043E\u0431\u043D\u0443\u044E \u0432\u0435\u0440\u0441\u0438\u044E." })), _jsx("div", { style: { flex: 76 } })] }))] })));
};
export default MainPage;
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: '1.75rem 2rem',
    },
    configRowContainer: {
        display: 'flex',
        height: '2.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
    },
    text: {
        marginRight: '1.125rem'
    },
    button: {
        height: '2.5rem'
    },
    variableDemoContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '0 0 0 4.25rem'
    },
    variableFullContentContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        textAlign: 'center'
    }
};
