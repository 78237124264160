import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { dispatchGetEvents } from 'dispatchers/events/dispatchGetEvents';
import { clearEventsDemo } from "actions/events/clearEventsDemo";
const EventsPage = () => {
    const [loading, setEditable] = useState(false);
    const dispatch = useDispatch();
    const events = useSelector((state) => state.adminEventConfigReducer);
    const navigationType = useSelector((state) => state.navigationBarConfigReducer.navigationType);
    const navigation = useNavigate();
    useEffect(() => {
        if (navigationType == 'adminNavigation') {
            dispatch(clearEventsDemo());
        }
        dispatch(dispatchGetEvents(navigationType));
    }, [dispatch, navigationType]);
    console.log('response events:', events);
    const dateFormatter = (startDate, endDate) => {
        const formattedStartDate = startDate.split('-');
        const formattedEndDate = endDate.split('-');
        const temporaryStartDate = [...formattedStartDate];
        const temporaryEndDate = [...formattedEndDate];
        temporaryStartDate[2] = '01';
        temporaryEndDate[2] = '01';
        let temporaryStringStartDate = temporaryStartDate.join('-');
        let temporaryStringEndDate = temporaryEndDate.join('-');
        if (new Date(temporaryStringStartDate).getTime() === new Date(temporaryStringEndDate).getTime()) {
            return formattedStartDate[2] + '-' + endDate.split('-').reverse().join('.');
        }
        else {
            temporaryStartDate[1] = '01';
            temporaryEndDate[1] = '01';
            temporaryStringStartDate = temporaryStartDate.join('-');
            temporaryStringEndDate = temporaryEndDate.join('-');
            if (new Date(temporaryStringStartDate).getTime() === new Date(temporaryStringEndDate).getTime()) {
                return formattedStartDate[2] + '.' + formattedStartDate[1] + '-' + endDate.split('-').reverse().join('.');
            }
        }
        return startDate.split('-').reverse().join('.') + '-' + endDate.split('-').reverse().join('.');
    };
    return (_jsxs(Container, Object.assign({ sx: { bgcolor: 'secondary.main' }, style: styles.container, maxWidth: false }, { children: [_jsx("div", Object.assign({ style: styles.newTourContainer }, { children: _jsx(Button, Object.assign({ onClick: () => navigation('/event/add') }, { children: _jsx(Typography, { children: "\u041D\u043E\u0432\u044B\u0439 \u0442\u0443\u0440\u043D\u0438\u0440" }) })) })), _jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: Object.values(events).map((event, eventIndex) => {
                    return _jsxs(Grid, Object.assign({ item: true, xs: 3, style: Object.assign(Object.assign({}, styles.gridItemContainer), { borderRight: (eventIndex + 1) % 4 == 0 ? 0 : '0.0625rem solid #000' }) }, { children: [event.eventCoverImage
                                ? _jsx("img", { style: styles.image, alt: 'Обложка турнира', src: event.eventCoverImage })
                                : null, _jsx(Typography, Object.assign({ variant: 'h3' }, { children: event.eventName })), _jsx(Typography, Object.assign({ variant: 'h3' }, { children: event.city })), _jsx(Typography, Object.assign({ variant: 'h3', style: { textAlign: 'right' } }, { children: dateFormatter(event.startDate, event.endDate) })), _jsxs("div", Object.assign({ style: styles.buttonsContainer }, { children: [_jsx(Button, Object.assign({ style: styles.startButton, onClick: () => navigation(`/event/${event.eventAddress}`) }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" })) })), _jsx(Button, Object.assign({ style: styles.startButton, onClick: () => navigation(`/secretary/event/${event.eventAddress}`) }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C" })) }))] }))] }), event.eventAddress);
                }) }))] })));
};
export default EventsPage;
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: '1.75rem 2rem',
    },
    newTourContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        marginBottom: '1.625rem'
    },
    gridItemContainer: {
        height: '22rem',
        display: 'flex',
        flexDirection: 'column',
        padding: '2rem',
        justifyContent: 'space-between'
    },
    image: {
        borderRadius: '0.625rem',
        width: '14.9375rem',
        height: '8.375rem',
        alignSelf: 'center'
    },
    startButton: {
        width: 'fit-content',
        alignSelf: 'center'
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
};
