import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Typography } from '@mui/material';
import { setDemoVersion } from 'store/navigationBarConfig/navigationBarConfig';
const ModeToggle = () => {
    const navigationType = useSelector((state) => state.navigationBarConfigReducer.navigationType);
    const dispatch = useDispatch();
    // Sync navigationType with localStorage
    useEffect(() => {
        const storedNavigationType = localStorage.getItem('navigationType');
        if (storedNavigationType && (storedNavigationType === 'adminNavigation' || storedNavigationType === 'demoNavigation')) {
            dispatch(setDemoVersion(storedNavigationType));
        }
    }, [dispatch]);
    // Whenever navigationType changes, store it in localStorage
    useEffect(() => {
        if (navigationType) {
            localStorage.setItem('navigationType', navigationType);
        }
    }, [navigationType]);
    const setNavigationTypeCallback = (checked) => {
        const newNavigationType = checked ? 'demoNavigation' : 'adminNavigation';
        dispatch(setDemoVersion(newNavigationType));
        localStorage.setItem('navigationType', newNavigationType); // Sync with localStorage
    };
    return (_jsxs("div", Object.assign({ style: styles.switchContainer }, { children: [_jsx(Typography, Object.assign({ variant: "h4", style: { marginRight: '0.5rem' } }, { children: "\u041F\u0440\u043E\u0431\u043D\u0430\u044F \u0432\u0435\u0440\u0441\u0438\u044F" })), _jsx(Switch, { checked: navigationType === 'demoNavigation', onChange: (event) => setNavigationTypeCallback(event.target.checked) })] })));
};
export default ModeToggle;
const styles = {
    switchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'end',
        marginBottom: '1rem',
    },
};
