import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, Link, Typography } from '@mui/material';
import { addParticipantToTournament, setPlayoffRounds } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
const CopyPastFieldComponent = () => {
    const [tableData, setTableData] = useState([]);
    const [incorrectData, setIncorrectData] = useState('');
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const stages = tournament ? tournament.stages : null;
    const participants = stages && stages.length > 0
        ? stages[stageAddress].participants
        : useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].participants);
    const dispatch = useDispatch();
    const importCallback = () => {
        if (tableData) {
            const headerColumns = ['Фамилия Имя', 'Клуб', 'Тренер', 'Уровень'];
            const firstRow = tableData[0].split('\t');
            let columnNamesMapper = { 'Фамилия Имя': -1, 'Клуб': -1, 'Тренер': -1, 'Уровень': -1 };
            let numberOfCoincidences = 0;
            while (headerColumns.length > 0) {
                let startNumberOfCoincidences = numberOfCoincidences;
                for (let i = 0; i < firstRow.length; i++) {
                    if (firstRow[i] === headerColumns[headerColumns.length - 1]) {
                        numberOfCoincidences++;
                        columnNamesMapper[headerColumns[headerColumns.length - 1]] = i;
                        headerColumns.pop();
                    }
                }
                if (startNumberOfCoincidences === numberOfCoincidences) {
                    break;
                }
            }
            let isCorrectData = true;
            Object.values(columnNamesMapper).forEach((value) => {
                if (value === -1) {
                    isCorrectData = false;
                }
            });
            const lastParticipantId = participants.length > 0 ? participants[participants.length - 1].id : -1;
            if (isCorrectData || firstRow.length === 4) {
                for (let i = isCorrectData ? 1 : 0; i < tableData.length; i++) {
                    const row = tableData[i].split('\t');
                    if (Number(row[columnNamesMapper['Уровень'] !== -1 ? columnNamesMapper['Уровень'] : 3])) {
                        dispatch(addParticipantToTournament({
                            eventAddress,
                            tournamentAddress,
                            stageAddress,
                            participant: {
                                id: (Number(lastParticipantId) + i + (isCorrectData ? 0 : 1)).toString(),
                                name: row[columnNamesMapper['Фамилия Имя'] !== -1 ? columnNamesMapper['Фамилия Имя'] : 0],
                                nameClub: row[columnNamesMapper['Клуб'] !== -1 ? columnNamesMapper['Клуб'] : 1],
                                count: 0,
                                conceded: 0,
                                win: 0,
                                lose: 0,
                                draw: 0,
                                trainerName: row[columnNamesMapper['Тренер'] !== -1 ? columnNamesMapper['Тренер'] : 2],
                                protestCounter: 0,
                                cards: [],
                                rating: row[columnNamesMapper['Уровень'] !== -1 ? columnNamesMapper['Уровень'] : 3],
                                status: false,
                                reachedStage: 0,
                                isPriorityParticipant: false,
                                goesFurther: false
                            }
                        }));
                        if (stages && stages[stageAddress] && stages[stageAddress].type === 'playoff') {
                            dispatch(setPlayoffRounds({
                                eventAddress,
                                tournamentAddress,
                                stageAddress
                            }));
                        }
                    }
                    else {
                        setIncorrectData('Неверный формат данных');
                    }
                }
                setTableData([]);
            }
            else {
                setIncorrectData('Неверный формат данных');
            }
        }
    };
    const changeImportField = (text) => {
        setTableData(text.split('\n'));
        setIncorrectData('');
    };
    return (_jsxs("div", Object.assign({ style: styles.container }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: Object.assign(Object.assign({}, styles.text), { textTransform: 'uppercase' }) }, { children: 'Импорт' })), _jsxs(Typography, Object.assign({ noWrap: true, variant: 'h4', style: Object.assign(Object.assign({}, styles.text), { whiteSpace: 'pre-line' }) }, { children: [`Вставьте в поле ниже отформатированные данные из excel таблицы.
                Примеры: `, _jsx(Link, Object.assign({ href: 'https://docs.google.com/spreadsheets/d/1F-uhi_6XEyeISCnPRQDZtD9DlQbCQM1orECqaLCInFg/edit#gid=1720747766' }, { children: 'таблица с данными' })), ' и связанная с ней ', _jsx(Link, Object.assign({ href: 'https://docs.google.com/forms/d/e/1FAIpQLSc2TqV4lQ-HuAhJ5DEKjyX8qtDgt8o9H_IcvLp-Vb2VO1RZrg/viewform' }, { children: 'форма регистрации' })), `.
                 Колонка с уровнями заполняется вручную. Можно вписывать любые целочисленные значения, чем больше число, тем сильнее считается участник.
                В поле ниже нужно вставить (ctrl-c - ctrl-v) 4 колонки в указанном порядке: “Фамилия Имя”, “Клуб”, “Тренер”, “Уровень”.`] })), _jsx(TextField, { style: styles.importTextContainer, InputProps: { style: styles.importTextInputProps }, multiline: true, onChange: (event) => changeImportField(event.target.value), value: tableData }), _jsx("div", Object.assign({ style: { height: '1.5rem' } }, { children: incorrectData
                    ? _jsx(Typography, Object.assign({ variant: 'h4', style: { color: 'red' } }, { children: incorrectData }))
                    : null })), !tournament.tournamentWasStarted
                ? _jsx(Button, Object.assign({ style: { width: 'fit-content', }, onClick: () => importCallback() }, { children: _jsx(Typography, { children: "\u0418\u043C\u043F\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" }) }))
                : null] })));
};
export default CopyPastFieldComponent;
const styles = {
    container: {
        marginTop: '1.625rem',
        display: 'flex',
        flexDirection: 'column',
    },
    text: {
        color: '#000',
        marginBottom: '1rem'
    },
    importTextContainer: {
        width: '100%',
        justifyContent: 'top',
        marginBottom: '1rem',
    },
    importTextInputProps: {
        width: '100%',
        height: '6.5625rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top'
    },
};
