var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addEvent } from 'actions/events/addEvent';
import { addEventDemo } from 'actions/events/addEventDemo';
import { dispatchGetEvents } from './dispatchGetEvents';
export const dispatchAddEvent = (navigationType, event) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        if (navigationType === 'adminNavigation') {
            yield dispatch(addEvent(event));
            dispatch(dispatchGetEvents(navigationType));
        }
        else if (navigationType === 'demoNavigation') {
            dispatch(addEventDemo(event));
        }
        return Promise.resolve();
    });
};
