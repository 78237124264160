import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { controlBoardInitialState } from 'store/mockData';
import { increaseParticipantCount, setMatch } from 'store/controlBoardSlice/controlBoardSlice';
import { changePhase } from 'store/secretaryEventConfig/secretaryEventConfig';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import translationPageManager from 'utils/translationPageManager';
import ParticipantPanelComponent from './components/ParticipantPanelComponent';
import StartStopMenuComponent from './components/StartStopMenuComponent';
import FightControlMenuContainer from './components/FightControlMenuContainer';
import TimerLogsComponent from './components/TimerLogsComponent';
const ControlPanelPage = (props) => {
    const { pathname } = useLocation();
    const { eventAddress, tournamentAddress } = useSecretaryPathParserHook();
    const event = useSelector((state) => state.adminEventConfigReducer[eventAddress]);
    const tournament = event ? event.tournaments[tournamentAddress] : null;
    const stageAddress = tournament ? tournament.currentStageIndex : null;
    const stage = tournament && (stageAddress || stageAddress === 0) ? tournament.stages[stageAddress] : null;
    const showProtestCounter = props.isStandAlone
        ? props.isStandAlone
        : useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].protestCounter);
    const participants = useSelector((state) => state.controlBoardReducer.participants);
    const inputRef = React.useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { checkTranslationPageAreExisting } = translationPageManager();
    useEffect(() => {
        if (!props.isStandAlone && !stage)
            navigate('/secretary/event/' + eventAddress);
    }, [stage, stageAddress]);
    useEffect(() => {
        dispatch(changePhase({
            phaseTranslation: 'scoreboardPhase',
            currentSecretaryPath: pathname
        }));
        checkTranslationPageAreExisting();
        if (!eventAddress) {
            dispatch(setMatch(controlBoardInitialState));
        }
    }, []);
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [participants[0].count, participants[1].count]);
    const handleKeyPress = useCallback((event) => {
        if (event.target !== inputRef.current) {
            if (event.key === 'ArrowLeft') {
                if (!(participants[0].count <= 0 && event.shiftKey)) {
                    dispatch(increaseParticipantCount({
                        participantNumber: 0,
                        addedCount: event.shiftKey ? -1 : 1
                    }));
                }
            }
            if (event.key === 'ArrowRight') {
                if (!(participants[1].count <= 0 && event.shiftKey)) {
                    dispatch(increaseParticipantCount({
                        participantNumber: 1,
                        addedCount: event.shiftKey ? -1 : 1
                    }));
                }
            }
        }
    }, [participants[0].count, participants[1].count]);
    return (_jsxs(Container, Object.assign({ style: styles.scoreBoardContainer, maxWidth: false }, { children: [_jsxs(Container, Object.assign({ style: styles.participantPanelsContainer, maxWidth: false }, { children: [_jsx(ParticipantPanelComponent, { participantNumber: 0, showProtestCounter: showProtestCounter }), _jsx(ParticipantPanelComponent, { participantNumber: 1, showProtestCounter: showProtestCounter })] })), _jsxs(Container, Object.assign({ style: styles.judgePanelContainer, maxWidth: false }, { children: [_jsx(TimerLogsComponent, {}), _jsx(StartStopMenuComponent, {}), _jsx(FightControlMenuContainer, { inputRef: inputRef })] }))] })));
};
export default ControlPanelPage;
const styles = {
    scoreBoardContainer: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#000',
        padding: 0
    },
    participantPanelsContainer: {
        flex: 461,
        width: '100%',
        display: 'flex',
        padding: 0,
        flexDirection: 'row',
    },
    judgePanelContainer: {
        flex: 563,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    },
};
