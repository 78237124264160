import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setDemoVersion } from 'store/navigationBarConfig/navigationBarConfig';
import AdminNavigationBar from 'pages/event/components/AdminNavigationBar';
import DemoNavigationBar from 'components/DemoNavigationBar';
import ModeToggle from './ModeToggle';
const LayoutWithTopBarNavigation = () => {
    const { pathname } = useLocation();
    const navigationType = useSelector((state) => state.navigationBarConfigReducer.navigationType);
    const dispatch = useDispatch();
    const navigationPanel = {
        'demoNavigation': _jsx(DemoNavigationBar, {}),
        'adminNavigation': _jsx(AdminNavigationBar, {}),
    };
    useLayoutEffect(() => {
        if (pathname === '/') {
            dispatch(setDemoVersion('adminNavigation'));
        }
        else {
            dispatch(setDemoVersion('demoNavigation'));
        }
    }, [pathname]);
    return (_jsxs("div", Object.assign({ style: styles.layoutContainer }, { children: [(pathname.startsWith('/event') || pathname === '/') ? navigationPanel[navigationType] : null, _jsxs("div", Object.assign({ style: styles.layoutContainer }, { children: [(pathname.startsWith('/event')) ? (_jsx(ModeToggle, {})) : "", _jsx(Outlet, {})] }))] })));
};
const styles = {
    layoutContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
    }
};
export default LayoutWithTopBarNavigation;
