import { createSlice } from '@reduxjs/toolkit';
import { seeding } from 'utils/seeding';
import { winRateCounter } from 'utils/winRateCounter';
import { addParticipantToNotDistributedList } from './utils';
import { loadEvents } from 'actions/events/loadEvents';
import { addEvent } from "actions/events/addEvent";
import { addEventDemo } from 'actions/events/addEventDemo';
import { setEvents } from "actions/events/setEvents";
import { clearEventsDemo } from 'actions/events/clearEventsDemo';
import { addTournament } from 'actions/actionsTournaments';
const adminEventConfig = createSlice({
    name: 'adminEventConfig',
    initialState: {},
    reducers: {
        deleteTournament(state, action) {
            const { eventAddress, tournamentAddress } = Object.assign({}, action.payload);
            if (state[eventAddress]
                .tournaments
                .hasOwnProperty(tournamentAddress)) {
                delete state[eventAddress].tournaments[tournamentAddress];
            }
        },
        changeTournament(state, action) {
            const { eventAddress, tournamentAddress } = Object.assign({}, action.payload);
            if (state[eventAddress]
                .tournaments.hasOwnProperty(tournamentAddress)) {
                state[eventAddress]
                    .tournaments[tournamentAddress] = action.payload.tournament;
            }
        },
        addStage(state, action) {
            const { eventAddress, tournamentAddress } = Object.assign({}, action.payload);
            const tournament = state[eventAddress].tournaments[tournamentAddress];
            const stages = tournament.stages;
            const passedParticipants = stages.length > 0
                ? [
                    ...stages[stages.length - 1].passedParticipants.map(participant => {
                        return Object.assign(Object.assign({}, participant), { goesFurther: false });
                    }),
                ]
                : [];
            const stage = stages.length === 0
                ? Object.assign(Object.assign({}, action.payload.stage), { notDistributedParticipants: tournament.participants, participants: tournament.participants }) : Object.assign(Object.assign({}, action.payload.stage), { participants: stages[stages.length - 1] ? passedParticipants : [], notDistributedParticipants: stages[stages.length - 1] ? passedParticipants : [] });
            state[eventAddress]
                .tournaments[tournamentAddress]
                .stages.push(stage);
        },
        deleteStage(state, action) {
            const { eventAddress, tournamentAddress, stageAddress } = Object.assign({}, action.payload);
            const tournament = state[eventAddress].tournaments[tournamentAddress];
            tournament.currentStageIndex = tournament.currentStageIndex <= stageAddress && tournament.currentStageIndex !== 0
                ? stageAddress - 1
                : 0;
            tournament.stages =
                state[eventAddress]
                    .tournaments[tournamentAddress]
                    .stages.filter((stage, index) => {
                    return index != stageAddress;
                });
            tournament.tournamentWasStarted = stageAddress !== 0;
        },
        changeStage(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, stage } = Object.assign({}, action.payload);
            state[eventAddress]
                .tournaments[tournamentAddress]
                .stages[stageAddress] = stage;
        },
        addParticipantToTournament(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, participant } = Object.assign({}, action.payload);
            const tournament = state[eventAddress].tournaments[tournamentAddress];
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            if (tournament.currentStageIndex === 0) {
                tournament.participants.push(participant);
            }
            if (state[eventAddress].tournaments[tournamentAddress].stages.length > 0) {
                tournament.stages[stageAddress].participants.push(participant);
                const sortParticipants = (firstParticipant, secondParticipant) => {
                    return winRateCounter(secondParticipant) - winRateCounter(firstParticipant);
                };
                stage.participants = tournament.stages[stageAddress].participants.sort(sortParticipants);
                stage.notDistributedParticipants.push(participant);
                stage.notDistributedParticipants = tournament.stages[stageAddress].notDistributedParticipants.sort(sortParticipants);
                if (stage.type === 'playoff') {
                    stage.isCorrectPlayoff = false;
                }
            }
        },
        setPlayoffRounds(state, action) {
            const { eventAddress, tournamentAddress, stageAddress } = Object.assign({}, action.payload);
            const tournament = state[eventAddress].tournaments[tournamentAddress];
            const stage = tournament.stages[stageAddress];
            if (stage.type === 'playoff') {
                let numberOfRounds = Math.ceil(Math.log(stage.participants.length) / Math.log(2) - 1) + 1;
                let firstRoundSize = Math.pow(2, numberOfRounds);
                const playoffRounds = [[]];
                stage.participants = stage.participants.map((participant) => {
                    return Object.assign(Object.assign({}, participant), { count: 0, cards: [], conceded: 0, isPriorityParticipant: false });
                });
                const playoffParticipants = [...stage.participants].sort((firstParticipant, secondParticipant) => {
                    if (stageAddress === 0) {
                        return Number(secondParticipant.rating) - Number(firstParticipant.rating);
                    }
                    else {
                        return winRateCounter(secondParticipant) - winRateCounter(firstParticipant);
                    }
                }).map((participant, participantIndex) => {
                    return participant
                        ? Object.assign(Object.assign({}, participant), { rating: (participantIndex + 1).toString() }) : null;
                });
                while (playoffParticipants.length < firstRoundSize) {
                    playoffParticipants.push(null);
                }
                const seedingArray = seeding(numberOfRounds);
                for (let i = 0; i < playoffParticipants.length; i = i + 2) {
                    playoffRounds[0].push({
                        matchId: playoffParticipants[seedingArray[i] - 1] && playoffParticipants[seedingArray[i + 1] - 1] ? `${playoffParticipants[seedingArray[i] - 1].id + '_' + playoffParticipants[seedingArray[i + 1] - 1].id}` : '',
                        matchStage: 'mainStage',
                        timerCounter: tournament.timerSettings === 'how much left' ? Number(tournament.matchDuration) * 1000 : 0,
                        intervalID: null,
                        participants: [playoffParticipants[seedingArray[i] - 1], playoffParticipants[seedingArray[i + 1] - 1]],
                        timerLogs: [],
                        countdown: tournament.timerSettings === 'how much left',
                        mainStageDurationSeconds: Number(tournament.matchDuration),
                        extraStageDurationSeconds: 30,
                        colors: ['#E46E8B', '#6E9EE4'],
                        isMatchCompleted: false
                    });
                }
                for (let i = numberOfRounds - 2; i >= 0; i--) {
                    const roundSize = Math.pow(2, i);
                    const round = [];
                    for (let j = 0; j < roundSize; j++) {
                        round.push({
                            matchId: '',
                            matchStage: 'mainStage',
                            timerCounter: tournament.timerSettings === 'how much left' ? Number(tournament.matchDuration) * 1000 : 0,
                            intervalID: null,
                            participants: [null, null],
                            timerLogs: [],
                            countdown: tournament.timerSettings === 'how much left',
                            mainStageDurationSeconds: Number(tournament.matchDuration),
                            extraStageDurationSeconds: 30,
                            colors: ['#E46E8B', '#6E9EE4'],
                            isMatchCompleted: false
                        });
                        if (i === 0 && (stage.isFinal || stage.goToTheNext === '2')) {
                            round.push({
                                matchId: '',
                                matchStage: 'mainStage',
                                timerCounter: tournament.timerSettings === 'how much left' ? Number(tournament.matchDuration) * 1000 : 0,
                                intervalID: null,
                                participants: [null, null],
                                timerLogs: [],
                                countdown: tournament.timerSettings === 'how much left',
                                mainStageDurationSeconds: Number(tournament.matchDuration),
                                extraStageDurationSeconds: 30,
                                colors: ['#E46E8B', '#6E9EE4'],
                                isMatchCompleted: false
                            });
                        }
                    }
                    stage.notDistributedParticipants = [];
                    playoffRounds.push(round);
                }
                if (stage.participants.length < firstRoundSize) {
                    for (let i = 0; i < playoffRounds[0].length; i++) {
                        if (!playoffRounds[0][i].participants[0] || !playoffRounds[0][i].participants[1]) {
                            if (i % 2 === 0) {
                                playoffRounds[1][Math.floor(i / 2)].participants[0] = playoffRounds[0][i].participants[0];
                            }
                            else {
                                playoffRounds[1][Math.floor(i / 2)].participants[1] = playoffRounds[0][i].participants[0];
                            }
                        }
                        if (playoffRounds[1][Math.floor(i / 2)].participants[0] && playoffRounds[1][Math.floor(i / 2)].participants[1]) {
                            playoffRounds[1][Math.floor(i / 2)].matchId = `${playoffRounds[1][Math.floor(i / 2)].participants[0].id + '_' + playoffRounds[1][Math.floor(i / 2)].participants[1].id}`;
                        }
                    }
                }
                // clearing empty matches from first round after bracket generation
                playoffRounds[0] = playoffRounds[0].map(match => {
                    if (match.participants[0] && match.participants[1]) {
                        return match;
                    }
                    else {
                        return Object.assign(Object.assign({}, match), { participants: [null, null] });
                    }
                });
                stage.playoffRounds = playoffRounds;
            }
        },
        deleteParticipant(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, participantId } = Object.assign({}, action.payload);
            state[eventAddress]
                .tournaments[tournamentAddress]
                .stages[stageAddress]
                .participants =
                state[eventAddress]
                    .tournaments[tournamentAddress]
                    .stages[stageAddress]
                    .participants.filter(participant => {
                    return participant.id !== participantId;
                });
            state[eventAddress].tournaments[tournamentAddress].stages =
                state[eventAddress]
                    .tournaments[tournamentAddress]
                    .stages.map(stage => {
                    const filteredNotDistributedParticipants = stage.notDistributedParticipants.filter(participant => {
                        return participant.id !== participantId;
                    });
                    let filteredStage = [];
                    if (stage.type === 'pools') {
                        filteredStage = stage.pools.map(pool => {
                            return Object.assign(Object.assign({}, pool), { participants: pool.participants.filter(participant => {
                                    return participant.id !== participantId;
                                }) });
                        });
                        return Object.assign(Object.assign({}, stage), { pools: filteredStage, notDistributedParticipants: [...filteredNotDistributedParticipants] });
                    }
                    else {
                        return stage;
                    }
                });
        },
        changeParticipant(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, participantId, goesFurther } = Object.assign({}, action.payload);
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            const nextStage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress + 1];
            stage.participants = state[eventAddress]
                .tournaments[tournamentAddress]
                .stages[stageAddress].participants.map(participant => {
                if (participant.id !== participantId) {
                    return participant;
                }
                else {
                    return Object.assign(Object.assign({}, participant), { goesFurther: goesFurther });
                }
            });
            stage.passedParticipants = stage.participants.filter(participant => {
                return participant.goesFurther;
            });
            if (nextStage) {
                const pastParticipants = stage.passedParticipants.map(participant => {
                    return Object.assign(Object.assign({}, participant), { goesFurther: false });
                });
                nextStage.participants = pastParticipants;
                nextStage.notDistributedParticipants = pastParticipants;
                nextStage.type === 'pools' ? nextStage.pools = [] : null;
            }
        },
        deletePool(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, poolAddress } = Object.assign({}, action.payload);
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            if (stage.type === 'pools') {
                const updatedNotDistributedParticipants = [...stage.notDistributedParticipants, ...stage.pools[poolAddress].participants]
                    .sort((firstParticipants, secondParticipants) => {
                    return Number(secondParticipants.rating) - Number(firstParticipants.rating);
                });
                const updatedPools = stage.pools.filter((pool, index) => {
                    return index !== poolAddress;
                });
                const updatedCurrentPoolIndex = stage.activePools.filter(index => {
                    return index !== poolAddress;
                }).map(element => {
                    if (element <= poolAddress) {
                        return element;
                    }
                    else {
                        return element - 1;
                    }
                });
                stage.pools = updatedPools;
                stage.notDistributedParticipants = updatedNotDistributedParticipants;
                stage.activePools = updatedCurrentPoolIndex;
            }
        },
        completePool(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, poolAddress } = Object.assign({}, action.payload);
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            if (stage.type === 'pools') {
                stage.activePools = stage.activePools.filter(element => element !== poolAddress);
            }
        },
        changePoolToActive(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, poolAddress } = Object.assign({}, action.payload);
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            if (stage.type === 'pools') {
                stage.activePools.push(poolAddress);
            }
        },
        cancelMatch(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, poolAddress, match } = Object.assign({}, action.payload);
            const tournament = state[eventAddress].tournaments[tournamentAddress];
            const stage = tournament.stages[stageAddress];
            if (stage.type === 'pools') {
                stage.completedMatches = stage.completedMatches.filter((matchItem) => matchItem.matchId !== match.matchId);
                stage.pools[poolAddress].matches = stage.pools[poolAddress].matches.map(matchItem => {
                    if (matchItem.matchId !== match.matchId) {
                        return matchItem;
                    }
                    else {
                        return match;
                    }
                });
                tournament.stages[stageAddress].participants = tournament.stages[stageAddress].participants.map(participant => {
                    if (participant.id !== match.participants[0].id && participant.id !== match.participants[1].id) {
                        return participant;
                    }
                    else {
                        const firstParticipantNumber = participant.id === match.participants[0].id ? 0 : 1;
                        const secondParticipantNumber = participant.id === match.participants[1].id ? 0 : 1;
                        participant.win = match.participants[firstParticipantNumber].count > match.participants[secondParticipantNumber].count ? --participant.win : participant.win;
                        participant.lose = match.participants[firstParticipantNumber].count < match.participants[secondParticipantNumber].count ? --participant.lose : participant.lose;
                        participant.draw = match.participants[firstParticipantNumber].count === match.participants[secondParticipantNumber].count ? --participant.draw : participant.draw;
                        participant.count = 0;
                        participant.cards = [];
                        participant.isPriorityParticipant = false;
                        return participant;
                    }
                });
            }
        },
        completeMatch(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, poolAddress, match } = Object.assign({}, action.payload);
            const tournament = state[eventAddress].tournaments[tournamentAddress];
            tournament.tournamentWasStarted = true;
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            tournament.stages[stageAddress].participants.map((participant) => {
                if (participant.id !== match.participants[0].id && participant.id !== match.participants[1].id) {
                    return participant;
                }
                else {
                    const firstParticipantNumber = participant.id === match.participants[0].id ? 0 : 1;
                    const secondParticipantNumber = participant.id === match.participants[1].id ? 0 : 1;
                    participant.win = match.participants[firstParticipantNumber].count > match.participants[secondParticipantNumber].count ? ++participant.win : participant.win;
                    participant.lose = match.participants[firstParticipantNumber].count < match.participants[secondParticipantNumber].count ? ++participant.lose : participant.lose;
                    participant.draw = match.participants[firstParticipantNumber].count === match.participants[secondParticipantNumber].count ? ++participant.draw : participant.draw;
                    participant.conceded = participant.conceded += participant.id === match.participants[0].id ? match.participants[1].count : match.participants[0].count;
                    participant.count = participant.count += participant.id === match.participants[0].id ? match.participants[0].count : match.participants[1].count;
                    return participant;
                }
            });
            if (stage.type === 'pools') {
                stage.completedMatches.push(Object.assign(Object.assign({}, match), { intervalID: null }));
                stage.pools[poolAddress].matches = stage.pools[poolAddress].matches.map((itemMatch) => {
                    if (itemMatch.matchId !== match.matchId) {
                        return itemMatch;
                    }
                    else {
                        return match;
                    }
                });
            }
            if (stage.type === 'playoff') {
                let currentMatchIndex = null;
                let currentRoundIndex = null;
                const winner = match.participants[0].count > match.participants[1].count
                    ? Object.assign(Object.assign({}, match.participants[0]), { win: match.participants[0].win + 1, count: 0, cards: [], status: false, isPriorityParticipant: false }) : Object.assign(Object.assign({}, match.participants[1]), { win: match.participants[1].win + 1, count: 0, cards: [], status: false, isPriorityParticipant: false });
                const loser = match.participants[0].count < match.participants[1].count
                    ? Object.assign(Object.assign({}, match.participants[0]), { lose: match.participants[0].lose + 1, count: 0, cards: [], status: false, isPriorityParticipant: false }) : Object.assign(Object.assign({}, match.participants[1]), { lose: match.participants[1].lose + 1, count: 0, cards: [], status: false, isPriorityParticipant: false });
                let lastRoundIndex = -1;
                if (!stage.isFinal) {
                    let numberOfRounds = Math.ceil(Math.log(stage.participants.length) / Math.log(2) - 1) + 1;
                    let numberOfRoundsCut = Math.ceil(Math.log(Number(stage.goToTheNext)) / Math.log(2) - 1) + 1;
                    lastRoundIndex = numberOfRounds - numberOfRoundsCut - 1;
                }
                stage.playoffRounds = stage.playoffRounds.map((round, roundIndex) => {
                    return round.map((matchInRound, matchIndex) => {
                        if (matchInRound.matchId !== match.matchId) {
                            if (stage.isFinal || roundIndex <= lastRoundIndex) {
                                if ((currentRoundIndex === 0 || currentRoundIndex)
                                    && (currentRoundIndex + 1) === roundIndex
                                    && (currentMatchIndex === 0 || currentMatchIndex)) {
                                    if (Math.floor(currentMatchIndex / 2) === matchIndex) {
                                        return Object.assign(Object.assign({}, match), { matchId: currentMatchIndex % 2 === 0 && matchInRound.participants[1]
                                                ? `${winner.id + '_' + matchInRound.participants[1].id}`
                                                : currentMatchIndex % 2 !== 0 && matchInRound.participants[0]
                                                    ? `${matchInRound.participants[0].id + '_' + winner.id}`
                                                    : '', participants: currentMatchIndex % 2 === 0
                                                ? [winner, matchInRound.participants[1]]
                                                : [matchInRound.participants[0], winner], isMatchCompleted: false });
                                    }
                                    else if (currentRoundIndex === stage.playoffRounds.length - 2 && matchIndex === 1) {
                                        return Object.assign(Object.assign({}, match), { matchId: currentMatchIndex % 2 === 0 && matchInRound.participants[1]
                                                ? `${loser.id + '_' + matchInRound.participants[1].id}`
                                                : currentMatchIndex % 2 !== 0 && matchInRound.participants[0]
                                                    ? `${matchInRound.participants[0].id + '_' + loser.id}`
                                                    : '', participants: currentMatchIndex % 2 === 0
                                                ? [loser, matchInRound.participants[1]]
                                                : [matchInRound.participants[0], loser], isMatchCompleted: false });
                                    }
                                    else {
                                        return matchInRound;
                                    }
                                }
                                else {
                                    return matchInRound;
                                }
                            }
                            else {
                                return matchInRound;
                            }
                        }
                        else {
                            currentRoundIndex = roundIndex;
                            currentMatchIndex = matchIndex;
                            return Object.assign(Object.assign({}, match), { isMatchCompleted: true });
                        }
                    });
                });
                if (!stage.isFinal && currentRoundIndex === lastRoundIndex) {
                    stage.passedParticipants.push(winner);
                }
            }
        },
        switchCurrentStageIndex(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, isUpStageIndex } = Object.assign({}, action.payload);
            const passedParticipants = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress].passedParticipants;
            const nextStage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress + 1];
            nextStage
                ? nextStage.participants = [...passedParticipants].map(participant => {
                    return Object.assign(Object.assign({}, participant), { goesFurther: false });
                })
                : null;
            state[eventAddress].tournaments[tournamentAddress].currentStageIndex += isUpStageIndex ? 1 : -1;
        },
        dropFromBracketToBracket(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, fromRound, fromMatch, fromPlace, toRound, toMatch, toPlace } = Object.assign({}, action.payload);
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            if (stage.type === 'playoff') {
                const currentParticipant = stage.playoffRounds[fromRound][fromMatch].participants[fromPlace];
                const deletingParticipant = stage.playoffRounds[toRound][toMatch].participants[toPlace];
                if (deletingParticipant) {
                    stage.notDistributedParticipants = addParticipantToNotDistributedList(stage.notDistributedParticipants, deletingParticipant, stageAddress);
                }
                stage.playoffRounds[toRound][toMatch].participants[toPlace] = stage.playoffRounds[fromRound][fromMatch].participants[fromPlace];
                stage.playoffRounds[toRound][toMatch].participants[toPlace].count = 0;
                stage.playoffRounds[fromRound][fromMatch].participants[fromPlace] = null;
                stage.playoffRounds[fromRound][fromMatch].matchId = '';
                if (currentParticipant && deletingParticipant) {
                    stage.playoffRounds[toRound][toMatch].matchId = `${currentParticipant.id + '_' + deletingParticipant.id}`;
                }
            }
        },
        dropFromListToBracket(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, toRound, toMatch, toPlace, participant } = Object.assign({}, action.payload);
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            if (stage.type === 'playoff') {
                stage.notDistributedParticipants = stage.notDistributedParticipants.filter(iterationParticipant => {
                    return iterationParticipant.id !== participant.id;
                });
                const deletingParticipant = stage.playoffRounds[toRound][toMatch].participants[toPlace];
                stage.playoffRounds[toRound][toMatch].participants[toPlace] = Object.assign(Object.assign({}, participant), { cards: [] });
                const currentParticipant = stage.playoffRounds[toRound][toMatch].participants[toPlace];
                const secondParticipant = stage.playoffRounds[toRound][toMatch].participants[toPlace === 0 ? 1 : 0];
                if (currentParticipant && secondParticipant) {
                    stage.playoffRounds[toRound][toMatch].matchId = `${currentParticipant.id + '_' + secondParticipant.id}`;
                }
                if (deletingParticipant) {
                    stage.notDistributedParticipants = addParticipantToNotDistributedList(stage.notDistributedParticipants, deletingParticipant, stageAddress);
                }
            }
        },
        deleteFromBracket(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, fromRound, fromMatch, fromPlace, } = Object.assign({}, action.payload);
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            if (stage.type === 'playoff') {
                const participant = stage.playoffRounds[fromRound][fromMatch].participants[fromPlace];
                if (participant) {
                    stage.notDistributedParticipants = addParticipantToNotDistributedList(stage.notDistributedParticipants, participant, stageAddress);
                    stage.playoffRounds[fromRound][fromMatch].participants[fromPlace].count = 0;
                    stage.playoffRounds[fromRound][fromMatch].participants[fromPlace] = null;
                    stage.playoffRounds[fromRound][fromMatch].matchId = '';
                }
            }
        },
        startPlayoff(state, action) {
            const { eventAddress, tournamentAddress, stageAddress, isCorrectPlayoff } = Object.assign({}, action.payload);
            const stage = state[eventAddress].tournaments[tournamentAddress].stages[stageAddress];
            if (stage.type === 'playoff') {
                if (stage.playoffRounds) {
                    stage.isCorrectPlayoff = isCorrectPlayoff;
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadEvents.fulfilled, (state, action) => {
            if (action.payload) {
                action.payload.map((event) => {
                    state[event.eventAddress] = event;
                });
            }
        })
            .addCase(setEvents, (state, action) => {
            return Object.assign({}, action.payload);
        })
            .addCase(addEvent.fulfilled, (state, action) => {
            const event = action.payload;
            if (action.payload) {
                state[event.eventAddress] = event;
                console.log("ADDED", event);
            }
        })
            .addCase(addEventDemo, (state, action) => {
            const event = action.payload;
            if (action.payload) {
                state[event.eventAddress] = event;
            }
        })
            .addCase(clearEventsDemo, () => {
            return {};
        })
            .addCase(addTournament, (state, action) => {
            const { eventAddress, tournamentAddress } = Object.assign({}, action.payload);
            state[eventAddress].tournaments[tournamentAddress] = action.payload.tournament;
        });
    },
});
export default adminEventConfig.reducer;
export const { changeTournament, deleteTournament, addStage, changeStage, deleteStage, addParticipantToTournament, setPlayoffRounds, deleteParticipant, changeParticipant, deletePool, completePool, changePoolToActive, completeMatch, cancelMatch, switchCurrentStageIndex, dropFromBracketToBracket, dropFromListToBracket, deleteFromBracket, startPlayoff } = adminEventConfig.actions;
