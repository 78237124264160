import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Container } from '@mui/material';
import { emptyTournament } from 'store/mockData';
import { addTournament } from "actions/actionsTournaments";
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import { tournamentValidationSchema } from 'utils/tournamentValidationSchema';
import TournamentSettingComponent from 'pages/event/components/TournamentSettingComponent';
const TournamentCreationPage = () => {
    const { eventAddress } = useAdminPathParserHook();
    const tournaments = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: emptyTournament,
        validationSchema: tournamentValidationSchema,
        validate: values => {
            const errors = emptyTournament;
            const nominationExists = Object.keys(tournaments).find(tournament => {
                return tournament == values.tournamentAddress;
            });
            if (nominationExists) {
                errors.tournamentAddress = 'Это название уже используется';
                return errors;
            }
        },
        onSubmit: values => {
            dispatch(addTournament({
                eventAddress,
                tournamentAddress: values.tournamentAddress,
                tournament: values
            }));
            navigate('/event/' + eventAddress + '/tournament/' + values.tournamentAddress + '/participants');
        },
    });
    return (_jsx(Container, Object.assign({ sx: { bgcolor: 'secondary.main' }, style: styles.mainContainer, maxWidth: false }, { children: _jsx(TournamentSettingComponent, { formik: formik, editable: true, fromPage: 'tournamentCreation' }) })));
};
export default TournamentCreationPage;
const styles = {
    mainContainer: {
        width: '100vw',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        paddingLeft: '8.375rem',
        paddingRight: '8.375rem',
        paddingBottom: '6.375rem'
    },
};
