import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Container } from '@mui/material';
import { STAGE_CHOOSER } from 'store/mockData';
import { addStage, setPlayoffRounds } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import StageSettingsComponent from 'pages/event/components/StageSettingsComponent';
import WithTournamentSubmenu from 'pages/event/components/WithTournamentSubmenu';
const AddStagePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { eventAddress, tournamentAddress } = useAdminPathParserHook();
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const formik = useFormik({
        initialValues: STAGE_CHOOSER['pools'],
        onSubmit: values => {
            dispatch(addStage({
                eventAddress: eventAddress,
                tournamentAddress: tournamentAddress,
                stage: values.type === 'pools'
                    ? Object.assign(Object.assign({}, values), { 
                        // @ts-ignore
                        isFinal: values.isFinal === 'true', pools: [], passedParticipants: [], 
                        // @ts-ignore
                        resolveTies: values.resolveTies === 'true' }) : values.type === 'playoff'
                    ? Object.assign(Object.assign({}, values), { 
                        // @ts-ignore
                        isFinal: values.isFinal === 'true', participants: [], playoffRounds: [], passedParticipants: [] }) : values,
                stageAddress: 0,
            }));
            dispatch(setPlayoffRounds({ eventAddress, tournamentAddress, stageAddress: tournament.stages.length }));
            navigate('/event/' + eventAddress + '/tournament/' + tournamentAddress + '/stage/' + tournament.stages.length + '/bracket');
        },
    });
    return (_jsx(Container, Object.assign({ style: styles.container, maxWidth: false }, { children: _jsx(StageSettingsComponent, { formik: formik, playoffSize: null }) })));
};
export default WithTournamentSubmenu(AddStagePage);
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        paddingLeft: '8.375rem',
        paddingRight: '8.375rem',
    },
};
